<template>
  <div class="addCoupon-page">
    <div class="box">
      <div class="title">基本信息</div>
      <div class="item">
        <span class="item-title">活动名称</span>
        <span class="item-content">{{couponDetail.coupon_name}}</span>
      </div>
      <div class="item">
        <span class="item-title">活动周期</span>
        <span class="item-content">{{couponDetail.start_time}}至{{couponDetail.dead_time}}</span>
      </div>
      <div class="item">
        <span class="item-title">优惠门槛</span>
        <span class="item-content" v-if="couponDetail.full_price == 0">无门槛使用</span>
        <span class="item-content" v-else>订单满{{couponDetail.full_price}}元可用</span>
      </div>
      <div class="item">
        <span class="item-title">发行总量</span>
        <span class="item-content">{{couponDetail.limit_num}}</span>
      </div>
      <div class="item">
        <span class="item-title">用券日期</span>
        <span class="item-content" v-if="couponDetail.time_limit == 1">
          <span class="mr10" v-for="(item, index) in couponDetail.receive_time" :key="index">{{item.start_time}}-{{item.end_time}}</span>
        </span>
        <span class="item-content" v-if="couponDetail.time_limit == 2">领券后生效，有效期{{couponDetail.receive_time}}天</span>
      </div>
      <div class="item">
        <span class="item-title">适用商圈</span>
        <span class="item-content">{{couponDetail.area}}</span>
      </div>
    </div>
    <div class="box">
      <div class="title">活动规则</div>
      <div class="item">
        <span class="item-title">活动对象</span>
        <span class="item-content" v-if="couponDetail.shop_limit && couponDetail.shop_limit.length === 0">全部商户</span>
        <div class="table-box" v-else>
          <el-table :data="shopData" style="width: 100%;margin-top: 20px;" border>
            <el-table-column prop="id" label="商户头像" align="center">
              <template slot-scope="scope">
                <img class="logo-img" :src="scope.row.logo_path" alt="">
              </template>
            </el-table-column>
            <el-table-column prop="shop_id" label="商户号" align="center"></el-table-column>
            <el-table-column prop="shop_name" label="商户名" align="center"></el-table-column>
            <el-table-column prop="contact_tel" label="联系方式" align="center"></el-table-column>
            <el-table-column prop="address" label="店铺地址" align="center"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="title">活动预算</div>
      <div class="item">
        <span class="item-title">限领规则</span>
        <span class="item-content" v-if="couponDetail.pur_limit_num == 0">不限购</span>
        <span class="item-content" v-else>每人限购{{couponDetail.pur_limit_num}}份</span>
      </div>
      <div class="item">
        <span class="item-title">优惠金额</span>
        <span class="item-content">{{couponDetail.ticket_money}}</span>
      </div>
      <div class="item">
        <span class="item-title">成本补贴</span>
        <span class="item-content">{{couponDetail.subsidy}}%</span>
      </div>
      <div class="item">
        <span class="item-title">预算成本</span>
        <span class="item-content">{{budgetCost}}元</span>
        <el-tooltip effect="dark" style="margin-left: 10px;" content="预算成本=发行总量*优惠金额*成本补贴比例" placement="top-start">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
    </div>
    <div class="box">
      <div class="title flexbox flex-justify-between">
        <span>订单信息</span>
        <span>共计优惠：{{discountMoney || 0}}元，平台补贴：{{subsidyMoney || 0}}元</span>
      </div>
      <div class="order-search-box">
        <el-date-picker
          v-model="time"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-input v-model="shop_name" style="width: 300px;margin-left: 10px;margin-right: 10px;" placeholder="请输入商家名称"></el-input>
        <el-button type="primary" @click="searchOrders">搜索</el-button>
        <el-button type="info" @click="clearOrders">清空</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%;margin-top: 20px;" border>
        <el-table-column prop="user_id" label="用户ID" align="center"></el-table-column>
        <el-table-column prop="user_name" label="昵称" align="center"></el-table-column>
        <el-table-column prop="user_tel" label="用户手机号" align="center"></el-table-column>
        <el-table-column prop="order_id" label="订单号" align="center"></el-table-column>
        <el-table-column prop="area_name" label="归属商圈" align="center"></el-table-column>
        <el-table-column prop="shop_name" label="店铺名称" align="center"></el-table-column>
        <el-table-column prop="shop_tel" label="商户账号" align="center"></el-table-column>
        <el-table-column prop="create_time" label="订单创建时间" align="center"></el-table-column>
        <el-table-column prop="pay_price" label="订单实付金额" align="center"></el-table-column>
        <el-table-column prop="discount_price" label="优惠金额" align="center"></el-table-column>
        <el-table-column prop="subsidy" label="平台补贴金额" align="center"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="mt10" 
        background 
        :page-count="totalPage" 
        :current-page.sync="currentPage" 
        @current-change="platCouponOrders">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  $sg_platCouponInfo, 
  $sg_activityShopList,
  $sg_platCouponOrders,
  $sg_platCouponTotalDiscount, 
} from '@/api/schoolGroupShops';

export default {
  data() {
    return {
      activity_ticket_id: '',
      couponDetail: {},
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      budgetCost: '', // 预算成本
      shopData: [],
      discountMoney: '',
      subsidyMoney: '',
      time: '',
      shop_name: '',
    };
  },
  created() {
    this.activity_ticket_id = this.$route.query.activity_ticket_id;
    this.getCouponDetail();
    this.platCouponTotalDiscount();
    this.platCouponOrders();
  },
  methods: {
    // 获取优惠券详情
    getCouponDetail() {
      const params = {
        activity_ticket_id: this.activity_ticket_id,
      };
      $sg_platCouponInfo(params).then((res) => {
        this.couponDetail = res;
        this.budgetCost = (res.limit_num * res.ticket_money * (res.subsidy / 100)).toFixed(2);
        this.activityShopList();
      });
    },
    // 获取商户列表
    activityShopList() {
      if (this.couponDetail.shop_limit.length === 0 || this.couponDetail.shop_limit[0] === '0') return;
      const params = {
        shop_ids: `${this.couponDetail.shop_limit}`,
      };
      $sg_activityShopList(params).then((res) => {
        this.shopData = res;
      });
    },
    // 优惠券优惠金额统计
    platCouponTotalDiscount() {
      const params = {
        id: this.activity_ticket_id,
      };
      $sg_platCouponTotalDiscount(params).then((res) => {
        this.discountMoney = res.total;
        this.subsidyMoney = res.subsidy;
      });
    },
    // 搜索优惠券
    searchOrders() {
      this.currentPage = 1;
      this.platCouponOrders();
    },
    // 清空优惠券
    clearOrders() {
      this.time = '';
      this.shop_name = '';
      this.platCouponOrders();
    },
    // 优惠券订单列表
    platCouponOrders() {
      const params = {
        id: this.activity_ticket_id,
        page: this.currentPage,
        page_size: 10,
        shop_name: this.shop_name,
        start_time: '',
        end_time: '',
      };
      if (this.time) {
        params.start_time = this.time[0];
        params.end_time = this.time[1];
      }
      $sg_platCouponOrders(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = res.total_page;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.addCoupon-page {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  .box {
    border: 1px solid #f0f0f0;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 15px;
    .item {
      width: 100%;
      padding-left: 40px;
      box-sizing: border-box;
      margin-top: 20px;
      .item-title {
        margin-right: 20px;
      }
    }
    .order-search-box {
      width: 100%;
      margin-top: 20px;
    }
  }
  .logo-img {
    width: 60px;
    height: 60px;
  }
}
</style>
